import { useRef } from 'react';
import AppleSignin from 'react-apple-signin-auth';

import { type AuthSuccessResult, isSSOError, signUpApple } from '@src/apiCalls';
import { primarySignupFormVar } from '@src/apollo/local';
import { SSOButton } from '@src/components/sso-buttons/sso-button';
import { getClickAdIds } from '@src/domains/foundation/tracking/cookies/tracking-cookies';
import { autoFill as squatchAutoFill } from '@src/lib/saasquatch';
import { CookieName, getCookie } from '@src/utils/cookies';
import { noop } from '@src/utils/functions';

type AppleUntyped = { authorization: any; user: any };

type Props = {
  signup?: boolean;
  signupSource?: string;
  referrerCode?: string;
  signupSourceQualifier?: string;
  redirectURL?: string;
  viewer?: {
    firstName: string;
    preferredName: string;
    lastName: string;
  };
  onSuccess: (result: AuthSuccessResult) => void;
  onFail?: (error: string) => void;
};

/** Apple Signin button */
export const AppleButton = ({
  onSuccess,
  redirectURL,
  signup,
  viewer,
  onFail = noop,
  ...props
}: Props) => {
  const anchorRef = useRef<Element>(null);
  const { nurseProfile } = primarySignupFormVar();
  const errorMessage =
    'Could not sign up using Apple. Please try signing up with your email instead.';

  const handleResult = async (response: AppleUntyped) => {
    try {
      const { gclid, gbraid, wbraid, ccuid, utmSource } = getClickAdIds();
      const referralCode = await squatchAutoFill();
      const referringDomain = getCookie(CookieName.ReferringDomain);

      const authorization = response.authorization;
      const user = response.user;
      const results = await signUpApple({
        signup: signup,
        identity_token: authorization?.id_token || '',
        signup_source: props.signupSource,
        signup_source_qualifier: props.signupSourceQualifier,
        initial_referring_domain: referringDomain,
        referrer_code: props.referrerCode ? [props.referrerCode] : [],
        referral_code: referralCode ?? undefined,
        first_name: viewer?.firstName || user?.name?.firstName || '',
        preferred_name: viewer?.preferredName || '',
        last_name: viewer?.lastName || user?.name?.lastName || '',
        role_id: nurseProfile.roleId,
        nurse_type: nurseProfile.nurseType,
        gclid,
        gbraid,
        wbraid,
        ccuid,
        source: utmSource,
      });

      if (isSSOError(results)) {
        onFail?.(results.sso_error || results.error || errorMessage);
      } else {
        localStorage.setItem('LAST_USED_SSO', 'apple');
        onSuccess(results);
      }
    } catch {
      // Usually network errors
      onFail?.(errorMessage);
    }
  };

  return (
    <AppleSignin
      ref={anchorRef}
      authOptions={{
        clientId: 'com.trustedhealth.web',
        scope: 'name email',
        redirectURI: redirectURL,
        usePopup: true,
      }}
      uiType="dark"
      className="apple-auth-btn"
      render={(props: { onClick: () => void }) => (
        <SSOButton provider="apple" onClick={props.onClick} />
      )}
      onError={() => onFail(errorMessage)}
      onSuccess={(result: AppleUntyped) => handleResult(result)}
    />
  );
};
